$almost-white: #dadada;
$dark-gold: #ff8605;
$light-gold: #ffad0e;
$yellow: #ffeb00;

$link-color: #ffad0e;

$header-height: 50px;

.gradiant-text {
	background: linear-gradient(0deg, $light-gold, $dark-gold 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: $light-gold;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100vh;
	position: relative;
	font-family: 'Roboto', sans-serif;
	color: $almost-white;
	font-size: 16px;
}

.background-gradiant {
	background: rgb(17, 14, 79);
	background: linear-gradient(rgba(3, 15, 29, 1) 100%, rgba(17, 14, 79, 1) 0%);
}

body {
	@extend .background-gradiant;
}

.page {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.page.landing-page {
	position: relative;
	flex-direction: column;

	section {
		position: relative;
	}

	.welcome-section {
		height: 100vh;
		background: url(https://pridelands-after-dark.com/assets/images/bi.jpg) no-repeat
			center center fixed;
		background-size: cover;

		.matrix-banner {
			position: absolute;
			bottom: 150px;
		}

		header {
			position: relative;
			top: 150px;
			text-align: center;
			font-weight: 400;

			h1,
			h2 {
				margin: 0;
				letter-spacing: 0.05em;
				text-shadow: 3px 2px 8px rgba(0, 0, 0, 0.75);
				font-family: 'Crimson Text', serif;
				font-weight: 400;
			}

			h2 {
				font-size: 2em;
			}

			h1 {
				@extend .gradiant-text;
				font-size: 8em;
			}
		}
	}

	.nala-welcome {
		position: relative;
		top: 100px;
		max-width: 90vw;
		max-height: 60vh;
	}
}

.page.matrix-page {
	max-width: 900px;
	margin: 100px auto;
	flex-direction: column;

	img {
		max-width: 100%;
	}

	h1 {
		font-size: 2.8em;
		border-bottom: thin solid $dark-gold;
		color: $light-gold;
	}
	a {
		color: $link-color;
	}
}

.important-banner {
	padding: 15px;
	text-align: center;
	font-size: 1.4em;
	color: black;
	background: rgb(255, 216, 0);
	background: linear-gradient(0deg, rgba(255, 216, 0, 1) 0%, rgba(255, 235, 0, 1) 100%);
}

main {
	height: 100%;
	color: white;
}

nav {
	display: flex;
}

nav a {
	color: $light-gold;
	text-align: center;
	cursor: pointer;
	display: block;
	padding: 5px 25px;
	font-family: 'Gelasio', serif;
	letter-spacing: 0.05rem;
}

nav a:hover {
	color: $dark-gold;
}

.main-header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
	display: flex;
	justify-content: flex-end;
	height: 50px;
	align-items: center;
	background-color: #030f1d;
}

.main-header.see-through {
	background-color: transparent;
}

.line {
	background-color: $light-gold;
	display: block;
	width: 70%;
}

.line.thin {
	height: 1px;
}

.matrix-banner {
	> p {
		padding: 15px 0;
	}
	background-color: $yellow;
	text-align: center;
	color: black;
	cursor: pointer;
	font-size: 1.1em;
	width: 100vw;
}

.matrix-banner:hover {
	background: rgb(255, 216, 0);
	background: linear-gradient(0deg, rgba(255, 216, 0, 1) 0%, rgba(255, 235, 0, 1) 100%);
	font-weight: bold;
}

.coming-soon-layout {
	top: 20vh;
	position: relative;
	max-width: 600px;
	margin: 0 auto;
	text-align: center;
	font-family: 'Gelasio', serif;
	background: linear-gradient(0deg, $light-gold, $dark-gold 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	h2,
	h3 {
		margin: 0;
	}

	h2 {
		font-size: 5em;
	}

	h3 {
		font-size: 10em;
	}
}

.adult-warning {
	max-width: 600px;
	margin: auto;
	margin-top: 75px;
	font-size: 2em;

	h1 {
		@extend .gradiant-text;
		text-align: center;
		font-size: 2em;
		margin: 15px;
	}

	p {
		margin-bottom: 15px;
	}

	.button-row {
		display: flex;
		justify-content: space-between;

		button {
			margin-top: 25px;
			padding: 15px;
			border: none;
			font-size: 0.6em;
			border-radius: 15px;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
		}

		.cancel {
			background-color: red;
			color: white;
		}

		.accept {
			background-color: green;
			color: white;
		}
	}
}
.nala-welcome-18 {
	max-width: 300px;
	margin: 0 auto;
	display: block;
}
